div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  color: #b3bdc6;
  background: #303336;
  margin: 0;
  font-family: Istok Web, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  line-height: normal !important;
}

body.loading #hud, body.loading footer, body.loading .breaker, body.loading #logo-wrapper {
  opacity: .2;
}

body.loading #count, body.loading #help, body.loadhelpvisible #count, body.loadhelpvisible #help, body.loading #summary {
  opacity: 0;
}

body.loading {
  pointer-events: none;
}

body.loading #loading {
  display: block;
}

body.expanded #wrapper {
  padding-bottom: 220px;
}

body.loadhelpvisible #hud, body.loadhelpvisible footer, body.loadhelpvisible .breaker {
  opacity: .2;
  pointer-events: none;
}

body.loadhelpvisible #json-data, body.loadhelpvisible .date-label-shown {
  filter: blur(5px);
  -moz-filter: blur(5px);
  pointer-events: none;
  opacity: .4 !important;
}

body.contentloaded #logo-wrapper, #body.loadhelpvisible #logo-wrapper {
  opacity: 1 !important;
}

body.intro .no-intro {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

body.intro #hud, body.intro footer, body.intro .breaker {
  opacity: 0;
}

body.intro #header {
  box-shadow: none;
}

body.intro #loadhelp {
  display: none;
}

body.intro-done .no-intro {
  opacity: 1;
  height: auto;
  overflow: visible;
}

a {
  cursor: pointer;
  color: #999;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

a img {
  border: none;
}

p {
  margin: 0 0 20px;
  padding: 0;
}

li {
  float: left;
  padding: 0 5px;
}

.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

strong, .strong {
  font-weight: 500;
}

#intro {
  background: #303336;
  position: absolute;
  top: 180px;
  left: 0;
}

#intro .panel {
  text-align: center;
  opacity: 0;
  vertical-align: middle;
  width: 80%;
  height: 100px;
  margin: auto;
  font-size: 1.1rem;
  position: absolute;
  inset: 0;
}

#skipintro {
  text-align: right;
  opacity: 0;
  width: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.panel-next {
  color: #000;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.panel-next:hover {
  color: #000;
}

.panel-circle {
  border-radius: 100%;
  margin: 0 auto 40px;
}

.panel-one .panel-circle {
  background: #fff;
  width: 150px;
  height: 150px;
}

.subtext-hidden {
  display: none;
}

.panel-off {
  top: -200px !important;
}

.panel-out {
  top: -1200px !important;
}

.panel-inner {
  position: relative;
}

.panel-two .panel-inner {
  top: -100px;
}

.panel-three .panel-inner {
  top: -45px;
}

.panel-text-h {
  color: #ffcc05;
}

.panel-icon {
  opacity: 0;
  background: url("icon-sprite-lrg.bc570c8f.png") no-repeat;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  padding-top: 90px;
  display: inline-block;
  position: relative;
}

.panel-icon:last-child {
  margin-right: 0;
}

.panel-icon.subtext-hidden {
  width: 0;
}

.panel-three .panel-image {
  margin-bottom: 30px;
}

.panel-icon-human-error {
  background-position: 0 0;
}

.panel-icon-weather {
  background-position: -70px 0;
}

.panel-icon-mechanical {
  background-position: -140px 0;
}

.panel-icon-criminal {
  background-position: -210px 0;
}

.panel-icon-unknown {
  background-position: -280px 0;
}

.panel-stat {
  background: #b3bdc6;
}

.panel-stat span {
  display: inline-block;
}

.panel-stat-sea {
  width: 150px;
  height: 150px;
}

.panel-stat-rail {
  width: 10px;
  height: 10px;
}

.panel-stat-road {
  width: 3px;
  height: 3px;
}

.panel-stat-air {
  background: #ffcc05;
  width: 1px;
  height: 1px;
}

.safety-table {
  width: 600px;
  margin: -40px auto 45px;
}

.safety-table td {
  vertical-align: middle;
  opacity: 0;
  width: 25%;
}

.safety-table td.safety-table-fade {
  opacity: 0;
}

.safety-table tr:first-child td {
  height: 20px;
}

.safety-table td.option-descriptor {
  padding: 0 0 20px;
}

.safety-table .panel-circle {
  margin-bottom: 20px;
}

.safety-table td strong {
  font-weight: 700;
}

.panel-text {
  text-align: center;
  margin: auto;
  position: relative;
}

.source {
  width: 600px;
  margin: 20px 0 0 -300px;
  position: fixed;
  bottom: 20px;
  left: 50%;
}

.source.out {
  bottom: -200px;
}

.source, .source a {
  color: #b3bdc64d;
}

.source a {
  text-decoration: underline;
}

.source a:hover {
  text-decoration: none;
}

.panel-four .panel-text {
  margin-bottom: 40px;
}

#closeloadhelp, .panel-next {
  text-decoration: none;
  box-shadow: 0 3px #0003;
}

#closeloadhelp:hover, .panel-next:hover {
  background-color: #ffcc05;
  text-decoration: none;
  box-shadow: 0 3px #00000080;
}

#closeloadhelp:active, .panel-next:active {
  background-color: #e6b300;
  position: relative;
  top: 1px;
  box-shadow: 0 2px #00000080;
}

#logo-wrapper, footer, #hud {
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

#container a, #container img {
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
}

#header, body, #footer, #summary {
  box-sizing: border-box;
}

#container {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px #00000001;
  padding: 0 0 0 30px;
}

#header {
  width: 100%;
  height: 136px;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 20px 40px #0000001a;
}

#header, #footer, #summary {
  z-index: 2147483647;
  background: #303336f2;
}

#footer, #summary {
  position: fixed;
}

#footer {
  width: 100%;
  padding: 15px 40px 13px;
  bottom: 0;
  left: 0;
  box-shadow: 0 -20px 40px #0000001a;
}

#wrapper {
  padding: 210px 10px 50px 30px;
}

#json-data {
  opacity: 1;
}

#hud, #footer, #help, #count, #clear, #standfirst, #summary-totals, #sort-form-selector, #loading span, #loadhelp {
  font-size: .9rem;
}

.option-descriptor, #tip, #labeltip, #yeartip, .panel-icon, .safety-table td, .source {
  font-size: .75rem;
}

#source, .year span.marker, .date-label {
  font-size: .5rem;
}

.info-l {
  font-size: 1.5rem;
}

#logo {
  position: absolute;
  top: 10px;
  left: 38px;
}

#standfirst {
  text-align: left;
  color: #fff;
  position: absolute;
  top: 73px;
  left: 40px;
}

h1 {
  letter-spacing: -1px;
  float: left;
  cursor: pointer;
  color: #fff;
  margin: 0 0 10px;
  padding: 0;
  font-size: 3rem;
  font-weight: 700;
  display: inline;
  position: absolute;
  top: 10px;
  left: 38px;
}

h1 .hh, .highlight {
  color: #ffcc05;
}

#future-header {
  z-index: 9999999;
  background: #fff;
  width: 100%;
  height: 30px;
  position: fixed;
  top: 40px;
  overflow: hidden;
  box-shadow: 0 10px 20px #0000004d;
}

#future-header-inner {
  float: right;
  background: url("future-header.53685657.png") 100% 0 no-repeat;
  width: 350px;
  height: 30px;
  margin-right: 20px;
}

#future-link {
  -moz-background-size: 90px;
  -webkit-background-size: 90px;
  -ms-background-size: 90px;
  background: url("future-logo.887a0cd8.png") 0 0 / 90px no-repeat;
  width: 180px;
  height: 30px;
  margin-left: 37px;
  display: block;
}

#future-link span {
  display: none;
}

.breaker {
  border-bottom: 1px solid #b3bdc6;
  height: 1px;
  margin: 0 40px;
  position: absolute;
  top: 100px;
}

#count {
  text-align: left;
  position: absolute;
  top: 109px;
  left: 40px;
}

#countno {
  font-weight: 700;
}

#clear {
  opacity: 0;
  position: absolute;
  top: 75px;
  right: 40px;
}

#clear img {
  opacity: .5;
  margin-left: 10px;
  position: relative;
  top: 1px;
}

#clear:hover img {
  opacity: .8;
}

#help {
  text-align: right;
  position: absolute;
  top: 109px;
  right: 40px;
}

.option-descriptor {
  letter-spacing: 1px;
  text-transform: uppercase;
}

#options {
  display: inline;
}

#option-set-phase {
  position: absolute;
  top: 15px;
  right: 220px;
}

#option-set-reason {
  position: absolute;
  top: 15px;
  right: 40px;
}

#sorts {
  position: absolute;
  top: 15px;
  right: 440px;
}

#closeloadhelp {
  color: #000;
  background: #fff url("start-icon.a43fe51d.png") 8px 7px no-repeat;
  border-radius: 5px;
  padding: 5px 10px 5px 35px;
  text-decoration: none;
  display: none;
  pointer-events: painted !important;
}

#closeloadhelp:hover {
  color: #000;
}

#loadhelp p {
  margin-top: 10px;
}

#share {
  position: absolute;
  bottom: 8px;
  right: 240px;
}

.share-button {
  opacity: .4;
  background: url("newsharesprite.c804f96d.png") no-repeat;
  width: 22px;
  height: 22px;
  display: inline-block;
}

.share-button:hover {
  opacity: 1;
}

.share-button span {
  display: none;
}

.share-fb {
  background-position: 0 0;
}

.share-tw {
  background-position: -22px 0;
}

.share-rd {
  background-position: -44px 0;
}

.share-dg {
  background-position: -66px 0;
}

.key {
  height: 20px;
  padding-left: 40px;
  display: inline-block;
}

.key span {
  position: relative;
  top: 1px;
}

.key-intensity {
  background: url("key-intensity.b30eb9ab.png") 0 0 no-repeat;
}

.key-bubblesize {
  background: url("key-bubblesize.ed0f1a4b.png") 0 0 no-repeat;
  margin-right: 20px;
}

.iibstudio {
  float: right;
  background: url("iibstudio-for-bbcfuture.fc6372fc.png") 100% 0 no-repeat;
  width: 155px;
  height: 24px;
  display: inline-block;
  position: fixed;
  bottom: 15px;
  right: 40px;
}

#source {
  z-index: 9999;
  color: #666;
  transform-origin: 100% 100%;
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 3);
  width: 180px;
  position: absolute;
  bottom: 240px;
  right: 10px;
  transform: rotate(-90deg);
}

.anim-slow {
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.anim-slow-opacity {
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.anim-xslow {
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.anim-xslow-opacity {
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.anim-fast {
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.anim-fast-opacity {
  -o-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
}

.anim-med {
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

#summary {
  opacity: 0;
  background: #3c4044;
  border-radius: 5px;
  width: 250px;
  padding: 20px;
  position: fixed;
  top: 150px;
  right: 40px;
}

#summary .option-descriptor.stats {
  float: left;
  display: inline;
  position: absolute;
  top: 20px;
}

#summary-graph {
  width: 250px;
  position: absolute;
  top: 20px;
}

.baseline {
  border-top: 1px solid #ffffff4d;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 7px;
}

.year {
  display: block;
  position: relative;
  left: 35px;
}

.year span.dot {
  background: #fff;
  border-radius: 6px;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  display: inline-block;
  position: absolute;
  bottom: -9px;
}

.year-breakdown {
  display: none;
}

.year label.marker {
  text-align: right;
  cursor: pointer;
  width: 20px;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: -35px;
}

.year label.marker:hover {
  color: #fff;
  opacity: 1 !important;
}

.year label.marker:hover + .bars .bar {
  z-index: 99999;
  height: 8px;
  margin-top: -2px;
}

.bar {
  width: 0;
  height: 4px;
  margin-left: -5px;
  position: absolute;
  left: 0;
}

.bar_ghost {
  background: none;
}

#year-breakdown-holder {
  display: none;
}

#summary-totals {
  padding: 0;
  line-height: 1.1rem;
  position: absolute;
}

.summary-total {
  text-align: center;
  width: 60px;
  margin: auto;
  padding: 3px 0 0;
  font-weight: 700;
  display: inline-block;
}

.summary-total-container.empty {
  opacity: .2;
  color: #aaa !important;
}

.summary-total-container {
  float: left;
  width: 60px;
  height: 27px;
  margin: 5px 0 0 -15px;
  display: inline;
}

.summary-total-container .icon {
  background-image: url("iconspritenew.186d9784.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: auto;
  padding: 2px;
  display: block;
}

#summary-total-human_error, .panel-icon-human-error {
  color: #ffcc05;
}

#summary-total-weather, .panel-icon-weather {
  color: #35c1e0;
}

#summary-total-mechanical, .panel-icon-mechanical {
  color: #faaa43;
}

#summary-total-criminal, .panel-icon-criminal {
  color: #d55d26;
}

#summary-total-unknown, .panel-icon-unknown {
  color: #998e7c;
}

#yeartip .summary-year-breakdown {
  margin-top: 10px;
}

#yeartip .summary-total-container {
  float: none;
  clear: both;
  width: 100%;
  margin: 0 0 -12px -15px;
  padding: 0;
  display: block;
  position: relative;
}

#yeartip .summary-total-container .icon {
  float: left;
  background-image: url("iconspritexsmall.38329668.png");
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  margin: 0 5px 0 15px;
  padding: 0;
  position: relative;
  top: 3px;
}

#yeartip .stc-human_error .icon {
  background-position: 0 0;
}

#yeartip .stc-weather .icon {
  background-position: 0 -10px;
}

#yeartip .stc-mechanical .icon {
  background-position: 0 -20px;
}

#yeartip .stc-criminal .icon {
  background-position: 0 -30px;
}

#yeartip .stc-unknown .icon {
  background-position: 0 -40px;
}

#yeartip .stc-tally {
  float: right;
  text-align: right;
  display: inline-block;
  position: relative;
  left: 10px;
}

#summary-info {
  line-height: 1;
  position: absolute;
  bottom: 20px;
}

#summary-info p {
  margin: 0;
}

.start-date {
  color: #fff;
  position: absolute;
  top: 150px;
  left: 40px;
}

.end-date {
  color: #fff;
  text-align: right;
  margin-bottom: 100px;
  position: absolute;
  left: 40px;
}

.date-label-shown {
  border-bottom: 1px dashed #3c4044;
}

.date-label-shown span {
  background: #3c4044;
  border-radius: 3px;
  padding: 3px 6px;
  display: inline-block;
  position: relative;
  top: 10px;
}

.date-label {
  border-radius: 4px;
  display: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.box {
  background: none;
  height: 0;
  margin-bottom: 75px;
  position: relative;
}

.box-parent {
  opacity: 0;
}

.box-inner {
  color: #666;
  background: #ccc;
  border: 3px solid #0000;
  border-radius: 300px;
  position: absolute;
}

.box-inner.f {
  border: 3px solid #0000;
  z-index: 9999999 !important;
  opacity: 1 !important;
}

.box.f {
  opacity: 1 !important;
}

.box.h {
  opacity: .1;
}

.box span {
  display: none;
}

.box.c_unknown .box-inner {
  opacity: .2;
}

.box.c_suspected .box-inner {
  opacity: .4;
}

.box.c_probable .box-inner {
  opacity: .6;
}

.box.c_confirmed .box-inner {
  opacity: .8;
}

.box.r_human_error .box-inner, .bar_human_error {
  background-color: #ffcc05;
}

.box.r_weather .box-inner, .bar_weather {
  background-color: #35c1e0;
}

.box.r_mechanical .box-inner, .bar_mechanical {
  background-color: #faaa43;
}

.box.r_criminal .box-inner, .bar_criminal {
  background-color: #d55d26;
}

.box.r_unknown .box-inner, .bar_unknown {
  background-color: #998e7c;
}

.info {
  display: none;
}

.info-label {
  text-transform: uppercase;
  color: #7e868c;
  display: block;
}

.info-l {
  margin-top: -5px;
  font-family: Oswald, sans-serif;
  display: block;
}

.info-s {
  font-weight: bold;
}

.info-d {
  border-top: 1px solid #0003;
  border-bottom: 1px solid #fff3;
  width: 180px;
  height: 2px;
  margin: 5px 0;
  display: block;
  position: relative;
  left: -10px;
}

.sort {
  display: inline;
}

span.label {
  float: left;
  display: inline;
}

#options input[type="checkbox"], #summary input[type="checkbox"] {
  display: none;
}

#options input[type="checkbox"] + label {
  opacity: .6;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  cursor: pointer;
  background-color: #303336;
  background-image: url("iconspritenew.186d9784.png");
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  width: 24px;
  height: 24px;
  margin-left: -2px;
  padding: 2px;
  display: inline-block;
  position: relative;
  top: -2px;
}

#options input[type="checkbox"] + label:hover {
  opacity: .8 !important;
}

#options input[type="checkbox"]:checked + label {
  opacity: 1 !important;
}

#options label span {
  display: none;
}

#option-set-phase input[type="checkbox"] + label {
  border-color: #fff0;
}

#option-set-phase input[type="checkbox"] + label:hover {
  border-color: #fff9;
}

#option-set-phase input[type="checkbox"]:checked + label {
  border-color: #fff;
}

#summary input[type="checkbox"]:checked + label {
  opacity: 1;
  color: #fff !important;
}

#summary input[type="checkbox"] + label:not(.selected) {
  opacity: .7;
}

#p_grounded + label {
  background-position: 0 0;
}

#p_takeoff + label {
  background-position: -28px 0;
}

#p_initial_climb + label {
  background-position: -56px 0;
}

#p_en_route + label {
  background-position: -84px 0;
}

#p_approach + label {
  background-position: -112px 0;
}

#p_landing + label {
  background-position: -140px 0;
}

#r_human_error + label, #summary-total-human_error .icon, .stc-human_error .icon {
  background-position: -168px 0;
  border-color: #ffcc0500;
}

#r_human_error + label:hover {
  border-color: #ffcc0599;
}

#r_human_error:checked + label {
  border-color: #ffcc05;
}

#r_weather + label, #summary-total-weather .icon, .stc-weather .icon {
  background-position: -196px 0;
  border-color: #35c1e000;
}

#r_weather + label:hover {
  border-color: #35c1e099;
}

#r_weather:checked + label {
  border-color: #35c1e0;
}

#r_mechanical + label, #summary-total-mechanical .icon, .stc-mechanical .icon {
  background-position: -224px 0;
  border-color: #faaa4300;
}

#r_mechanical + label:hover {
  border-color: #faaa4399;
}

#r_mechanical:checked + label {
  border-color: #faaa43;
}

#r_criminal + label, #summary-total-criminal .icon, .stc-criminal .icon {
  background-position: -252px 0;
  border-color: #d55d2600;
}

#r_criminal + label:hover {
  border-color: #d55d2699;
}

#r_criminal:checked + label {
  border-color: #d55d26;
}

#r_unknown + label, #summary-total-unknown .icon, .stc-unknown .icon {
  background-position: -280px 0;
  border-color: #998e7c00;
}

#r_unknown + label:hover {
  border-color: #998e7c99;
}

#r_unknown:checked + label {
  border-color: #998e7c;
}

#loading {
  text-align: center;
  opacity: 1;
  z-index: 99999;
  color: #f2f2f2;
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

#spinner {
  position: relative;
  top: 15px;
  left: -55px;
}

#loading span {
  text-align: left;
  width: 150px;
  display: inline-block;
  position: relative;
  top: 5px;
  left: -25px;
}

#loadhelp {
  z-index: 99999;
  color: #f2f2f2;
  text-align: center;
  background: #3c4044;
  border-radius: 6px;
  width: 210px;
  height: 205px;
  margin: auto;
  padding: 20px;
  position: fixed;
  inset: 0;
  box-shadow: 0 0 20px #0000004d;
}

#loadhelp.out {
  top: 2000px;
}

#debug {
  color: #46fa04;
  z-index: 9999;
  -o-transition: all .2s ease-in-out;
  opacity: .9;
  background: #000;
  border-right: 20px solid #000;
  width: 300px;
  padding: 5px;
  font-size: 10px;
  transition: all .2s ease-in-out;
  display: none;
  position: fixed;
  bottom: 100px;
  left: -300px;
}

#debug:hover {
  opacity: .9;
  left: 0;
}

.debug_float {
  float: left;
  width: 60px;
}

#labeltip, .info-s, .info-l, .chosen-results, #yeartip {
  color: #505d68;
}

#tip, #labeltip, #yeartip {
  cursor: default;
  z-index: 2147483647;
  word-wrap: normal;
  white-space: pre-line;
  background-color: #f2f2f2e6;
  border-radius: 3px;
  display: none;
  position: absolute;
}

#tip {
  padding: 10px;
}

#tip, #yeartip {
  text-align: left;
  width: 160px;
}

#labeltip {
  text-align: center;
  padding: 5px;
}

#yeartip {
  padding: 10px;
  position: fixed;
}

#tip:before, #labeltip:before, #yeartip:before {
  content: "";
  position: absolute;
}

#tip.n:before, #yeartip.n:before, #labeltip.n:before, #tip.s:before, #yeartip.s:before, #labeltip.s:before {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  margin-left: -5px;
  left: 50%;
}

#tip.e:before, #yeartip.e:before, #labeltip.e:before, #tip.w:before, #yeartip.w:before, #labeltip.w:before {
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  margin-top: -5px;
  top: 50%;
}

#tip.n:before, #yeartip.n:before, #labeltip.n:before {
  border-top: 10px solid #f2f2f2e6;
  bottom: -10px;
}

#tip.e:before, #yeartip.e:before, #labeltip.e:before {
  border-right: 10px solid #f2f2f2e6;
  left: -10px;
}

#tip.s:before, #yeartip.s:before, #labeltip.s:before {
  border-bottom: 10px solid #f2f2f2e6;
  top: -10px;
}

#tip.w:before, #yeartip.w:before, #labeltip.w:before {
  border-left: 10px solid #f2f2f2e6;
  right: -10px;
}

#tip.ne:before, #yeartip.ne:before, #labeltip.ne:before, #tip.se:before, #yeartip.se:before, #labeltip.se:before {
  border-left: 0;
  border-right: 10px solid #0000;
  left: 10px;
}

#tip.nw:before, #yeartip.nw:before, #labeltip.nw:before, #tip.sw:before, #yeartip.sw:before, #labeltip.sw:before {
  border-left: 10px solid #0000;
  border-right: 0;
  right: 10px;
}

#tip.ne:before, #yeartip.ne:before, #labeltip.ne:before, #tip.nw:before, #yeartip.nw:before, #labeltip.nw:before {
  border-top: 10px solid #f2f2f2e6;
  bottom: -10px;
}

#tip.se:before, #yeartip.se:before, #labeltip.se:before, #tip.sw:before, #yeartip.sw:before, #labeltip.sw:before {
  border-bottom: 10px solid #f2f2f2e6;
  top: -10px;
}

#tip.nw-alt:before, #yeartip.nw-alt:before, #labeltip.nw-alt:before, #tip.ne-alt:before, #yeartip.ne-alt:before, #labeltip.ne-alt:before, #tip.sw-alt:before, #yeartip.sw-alt:before, #labeltip.sw-alt:before, #tip.se-alt:before, #yeartip.se-alt:before, #labeltip.se-alt:before {
  border-top: 10px solid #f2f2f2e6;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  bottom: -10px;
  left: 10px;
}

#tip.ne-alt:before, #yeartip.ne-alt:before, #labeltip.ne-alt:before {
  left: auto;
  right: 10px;
}

#tip.sw-alt:before, #yeartip.sw-alt:before, #labeltip.sw-alt:before, #tip.se-alt:before, #yeartip.se-alt:before, #labeltip.se-alt:before {
  border-top: 0;
  border-bottom: 10px solid #f2f2f2e6;
  top: -10px;
  bottom: auto;
}

#tip.se-alt:before, #yeartip.se-alt:before, #labeltip.se-alt:before {
  left: auto;
  right: 10px;
}

.chosen-container {
  vertical-align: middle;
  zoom: 1;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  position: relative;
  top: 1px;
}

.chosen-container .chosen-drop {
  z-index: 1010;
  box-sizing: border-box;
  background: #f2f2f2;
  border-top: 0;
  width: 100%;
  position: absolute;
  top: 100%;
  left: -9999px;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
}

.chosen-container a {
  cursor: pointer;
}

.chosen-container-single .chosen-single {
  color: #444;
  white-space: nowrap;
  background-color: #f2f2f2;
  background-clip: padding-box;
  border-radius: 5px;
  height: 23px;
  padding: 0 0 0 8px;
  line-height: 24px;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.chosen-container-single .chosen-default {
  color: #999;
}

.chosen-container-single .chosen-single span {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 26px;
  display: block;
  overflow: hidden;
}

.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}

.chosen-container-single .chosen-single abbr {
  background: url("chosen-sprite.b5dc7e63.png") -42px 1px no-repeat;
  width: 12px;
  height: 12px;
  font-size: 1px;
  display: block;
  position: absolute;
  top: 6px;
  right: 26px;
}

.chosen-container-single .chosen-single abbr:hover, .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}

.chosen-container-single .chosen-single div {
  width: 18px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.chosen-container-single .chosen-single div b {
  background: url("chosen-sprite.b5dc7e63.png") 0 2px no-repeat;
  width: 100%;
  height: 100%;
  display: block;
}

.chosen-container-single .chosen-search {
  z-index: 1010;
  white-space: nowrap;
  margin: 0;
  padding: 3px 4px;
  position: relative;
}

.chosen-container-single .chosen-search input[type="text"] {
  box-sizing: border-box;
  background: url("chosen-sprite.b5dc7e63.png") 100% -20px no-repeat;
  border-radius: 0;
  outline: 0;
  width: 100%;
  height: auto;
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  font-family: sans-serif;
  font-size: 1em;
  line-height: normal;
}

.chosen-container-single .chosen-drop {
  background-clip: padding-box;
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px;
}

.chosen-container .chosen-results {
  text-indent: 2px;
  -webkit-overflow-scrolling: touch;
  max-height: 240px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.chosen-container .chosen-results li {
  -webkit-touch-callout: none;
  width: 100%;
  margin: 0;
  padding: 5px 6px;
  line-height: 15px;
  list-style: none;
  display: none;
}

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  width: 108px;
  display: list-item;
}

li.active-result:last-child:hover {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.chosen-container .chosen-results li.disabled-result {
  color: #ccc;
  cursor: default;
  display: list-item;
}

.chosen-container .chosen-results li.highlighted {
  color: #000;
  background-color: #ffcc05;
}

.chosen-container .chosen-results li.no-results {
  background: #f4f4f4;
  display: list-item;
}

.chosen-container .chosen-results li.group-result {
  cursor: default;
  font-weight: bold;
  display: list-item;
}

.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}

.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}

.chosen-container-multi .chosen-choices {
  box-sizing: border-box;
  cursor: text;
  border: 1px solid #aaa;
  width: 100%;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  height: auto !important;
}

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}

.chosen-container-multi .chosen-choices li.search-field {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  box-shadow: none;
  color: #666;
  border-radius: 0;
  outline: 0;
  height: 15px;
  margin: 1px 0;
  padding: 5px;
  font-family: sans-serif;
  font-size: 100%;
  line-height: normal;
  background: none !important;
  border: 0 !important;
}

.chosen-container-multi .chosen-choices li.search-field .default {
  color: #999;
}

.chosen-container-multi .chosen-choices li.search-choice {
  color: #333;
  cursor: default;
  background-clip: padding-box;
  border-radius: 3px;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  line-height: 13px;
  position: relative;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: url("chosen-sprite.b5dc7e63.png") -42px 1px no-repeat;
  width: 12px;
  height: 12px;
  font-size: 1px;
  display: block;
  position: absolute;
  top: 4px;
  right: 3px;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}

.chosen-container-multi .chosen-choices li.search-choice-disabled {
  color: #666;
  background-color: #e4e4e4;
  padding-right: 5px;
}

.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}

.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}

.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}

.chosen-container-multi .chosen-drop .result-selected {
  color: #ccc;
  cursor: default;
  display: list-item;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  opacity: .5;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: opacity .2s ease-out;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
  background: none;
  border-left: 0;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 2px;
}

.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #111 !important;
}

.chosen-disabled {
  cursor: default;
  opacity: .5 !important;
}

.chosen-disabled .chosen-single, .chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

.chosen-rtl {
  text-align: right;
}

.chosen-rtl .chosen-single {
  padding: 0 8px 0 0;
  overflow: visible;
}

.chosen-rtl .chosen-single span {
  direction: rtl;
  margin-left: 26px;
  margin-right: 0;
}

.chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}

.chosen-rtl .chosen-single div {
  left: 3px;
  right: auto;
}

.chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}

.chosen-rtl .chosen-choices li {
  float: right;
}

.chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}

.chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}

.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  left: 4px;
  right: auto;
}

.chosen-rtl.chosen-container-single-nosearch .chosen-search, .chosen-rtl .chosen-drop {
  left: 9999px;
}

.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}

.chosen-rtl .chosen-results li.group-option {
  padding-left: 0;
  padding-right: 0;
}

.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: 0;
}

.chosen-rtl .chosen-search input[type="text"] {
  direction: rtl;
  background: url("chosen-sprite.b5dc7e63.png") -30px -20px no-repeat;
  padding: 4px 5px 4px 20px;
}

.chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}

.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}

@media only screen and (-webkit-device-pixel-ratio >= 2), only screen and (resolution >= 144dpi) {
  .chosen-rtl .chosen-search input[type="text"], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type="text"], .chosen-container-multi .chosen-choices .search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
    background-image: url("chosen-sprite@2x.ea6c6734.png") !important;
    background-repeat: no-repeat !important;
    background-size: 52px 37px !important;
  }
}

@media (width <= 820px) {
  #hud, #help, .key {
    display: none;
  }
}

#browser {
  vertical-align: middle;
  text-align: center;
  width: 500px;
  height: 50px;
  margin: auto;
  font-size: 1rem;
  display: none;
  position: absolute;
  inset: 0;
}

html.no-cssanimations #browser, html.no-json #browser {
  display: block;
}

html.no-cssanimations .hfo, html.no-json .hfo {
  display: none !important;
}

/*# sourceMappingURL=index.5cf37663.css.map */
