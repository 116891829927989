/* Basic */
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  margin: 0px;
  color: rgba(179, 189, 198, 1);
  font-size: 62.5%;
  font-family: "Istok Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: normal !important;
}

body {
  background: rgba(48, 51, 54, 1);
}

body.loading #hud,
body.loading footer,
body.loading .breaker,
body.loading #logo-wrapper {
  opacity: 0.2;
}

body.loading #count,
body.loading #help,
body.loadhelpvisible #count,
body.loadhelpvisible #help,
body.loading #summary {
  opacity: 0;
}

body.loading #hud {
}

body.loading #container {
}

body.loading {
  pointer-events: none;
}

body.loading #loading {
  display: block;
}

body.expanded #wrapper {
  padding-bottom: 220px;
}

body.loadhelpvisible #hud,
body.loadhelpvisible footer,
body.loadhelpvisible .breaker {
  opacity: 0.2;
  pointer-events: none;
}

body.loadhelpvisible #json-data,
body.loadhelpvisible .date-label-shown {
  opacity: 0.4 !important;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -moz-filter: blur(5px);
  pointer-events: none;
}

body.contentloaded #logo-wrapper,
#body.loadhelpvisible #logo-wrapper {
  opacity: 1 !important;
}

body.intro .no-intro {
  opacity: 0;
  height: 0px;
  overflow: hidden;
}

body.intro #hud,
body.intro footer,
body.intro .breaker {
  opacity: 0;
}

body.intro #header {
  box-shadow: none;
}

body.intro #loadhelp {
  display: none;
}

body.intro-done .no-intro {
  opacity: 1;
  height: auto;
  overflow: visible;
}

a {
  cursor: pointer;
  color: #999;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: underline;
}

a img {
  border: none;
}

p {
  padding: 0;
  margin: 0px 0px 20px 0px;
}

li {
  float: left;
  padding: 0px 5px;
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

strong,
.strong {
  font-weight: 500;
}

/* Intro */

#intro {
  background: rgba(48, 51, 54, 1);
  position: absolute;
  top: 180px;
  left: 0px;
}

#intro .panel {
  width: 80%;
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  opacity: 0;
  font-size: 1.1rem;
  vertical-align: middle;
}

#skipintro {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
  opacity: 0;
  width: auto;
}

.panel-next {
  background: white;
  border-radius: 5px;
  color: black;
  padding: 5px 10px;
}

.panel-next:hover {
  color: black;
}

.panel-circle {
  border-radius: 100%;
  margin: 0px auto 40px auto;
}

.panel-one .panel-circle {
  width: 150px;
  height: 150px;
  background: white;
}

.subtext-hidden {
  display: none;
}

.panel-off {
  top: -200px !important;
}

.panel-out {
  top: -1200px !important;
}

.panel-inner {
  position: relative;
}

.panel-two .panel-inner {
  top: -100px;
}

.panel-three .panel-inner {
  top: -45px;
}

.panel-text-h {
  color: rgba(255, 204, 5, 1);
}

.panel-icon {
  position: relative;
  width: 70px;
  background: url(../img/icon-sprite-lrg.png) no-repeat;
  height: 70px;
  display: inline-block;
  opacity: 0;
  margin-right: 20px;
  padding-top: 90px;
}

.panel-icon:last-child {
  margin-right: 0px;
}

.panel-icon.subtext-hidden {
  width: 0px;
}

.panel-three .panel-image {
  margin-bottom: 30px;
}

.panel-icon-human-error {
  background-position: 0px 0px;
}

.panel-icon-weather {
  background-position: -70px 0px;
}

.panel-icon-mechanical {
  background-position: -140px 0px;
}

.panel-icon-criminal {
  background-position: -210px 0px;
}

.panel-icon-unknown {
  background-position: -280px 0px;
}

.panel-stat {
  background: rgb(179, 189, 198);
}

.panel-stat span {
  display: inline-block;
}

.panel-stat-sea {
  width: 150px;
  height: 150px;
}

.panel-stat-rail {
  width: 10px;
  height: 10px;
}

.panel-stat-road {
  width: 3px;
  height: 3px;
}

.panel-stat-air {
  width: 1px;
  height: 1px;
  background: rgba(255, 204, 5, 1);
}

.safety-table {
  width: 600px;
  margin: -40px auto 45px auto;
}

.safety-table td {
  width: 25%;
  vertical-align: middle;
  opacity: 0;
}

.safety-table td.safety-table-fade {
  opacity: 0;
}

.safety-table tr:first-child td {
  height: 20px;
}

.safety-table td.option-descriptor {
  padding: 0px 0px 20px 0px;
}

.safety-table .panel-circle {
  margin-bottom: 20px;
}

.safety-table td strong {
  font-weight: 700;
}

.panel-text {
  text-align: center;
  margin: auto;
  position: relative;
}

.source {
  margin: 20px 0px 0px 0px;
  position: fixed;
  left: 50%;
  margin-left: -300px;
  width: 600px;
  bottom: 20px;
}

.source.out {
  bottom: -200px;
}

.source,
.source a {
  color: rgba(179, 189, 198, 0.3);
}

.source a {
  text-decoration: underline;
}

.source a:hover {
  text-decoration: none;
}

.panel-four .panel-text {
  margin-bottom: 40px;
}

#closeloadhelp,
.panel-next {
  text-decoration: none;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
}

#closeloadhelp:hover,
.panel-next:hover {
  text-decoration: none;
  background-color: rgba(255, 204, 5, 1);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
}

#closeloadhelp:active,
.panel-next:active {
  position: relative;
  top: 1px;
  background-color: rgba(230, 179, 0, 1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
}

/* Structure */

#logo-wrapper,
footer,
#hud {
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
}

#container a,
#container img {
  -webkit-transition:
    opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  -moz-transition:
    opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  -o-transition:
    opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  -ms-transition:
    opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  transition:
    opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

#header,
body,
#footer,
#summary {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#container {
  padding: 0 0 0 30px;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

#header {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  height: 136px;
  position: fixed;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

#header,
#footer,
#summary {
  background: rgba(48, 51, 54, 0.95);
  z-index: 2147483647;
}

#footer,
#summary {
  position: fixed;
}

#footer {
  bottom: 0px;
  left: 0px;
  padding: 15px 40px 13px 40px;
  width: 100%;
  box-shadow: 0px -20px 40px rgba(0, 0, 0, 0.1);
}

#wrapper {
  padding: 210px 10px 50px 30px;
}

#json-data {
  opacity: 1;
}

/* Font sizes */

#hud,
#footer,
#help,
#count,
#clear,
#standfirst,
#summary-totals,
#sort-form-selector,
#loading span,
#loadhelp {
  font-size: 0.9rem;
}

.option-descriptor,
#tip,
#labeltip,
#yeartip,
.panel-icon,
.safety-table td,
.source {
  font-size: 0.75rem;
}

#source,
.year span.marker,
.date-label {
  font-size: 0.5rem;
}

.info-l {
  font-size: 1.5rem;
}

/* Header */

#logo {
  position: absolute;
  top: 10px;
  left: 38px;
}

#standfirst {
  position: absolute;
  text-align: left;
  left: 40px;
  top: 73px;
  color: white;
}

h1 {
  padding: 0px;
  margin: 0px 0px 10px 0px;
  position: absolute;
  top: 10px;
  left: 38px;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -1px;
  float: left;
  display: inline;
  cursor: pointer;
  color: white;
}

h1 .hh,
.highlight {
  color: rgba(255, 204, 5, 1);
}

#future-header {
  width: 100%;
  height: 30px;
  overflow: hidden;
  background: white;
  position: fixed;
  top: 40px;
  z-index: 9999999;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

#future-header-inner {
  width: 350px;
  margin-right: 20px;
  background: url("../img/bbc/future-header.png") no-repeat top right;
  float: right;
  height: 30px;
}

#future-link {
  display: block;
  height: 30px;
  width: 180px;
  background: url("../img/bbc/future-logo.png") no-repeat top left;
  -moz-background-size: 90px;
  -webkit-background-size: 90px;
  -ms-background-size: 90px;
  background-size: 90px;
  margin-left: 37px;
}

#future-link span {
  display: none;
}

.breaker {
  border-bottom: 1px solid rgba(179, 189, 198, 1);
  height: 1px;
  margin: 0px 40px;
  position: absolute;
  top: 100px;
}

#count {
  position: absolute;
  text-align: left;
  left: 40px;
  top: 109px;
}

#countno {
  font-weight: 700;
}

#clear {
  opacity: 0;
  position: absolute;
  right: 40px;
  top: 75px;
}

#clear img {
  position: relative;
  top: 1px;
  margin-left: 10px;
  opacity: 0.5;
}

#clear:hover img {
  opacity: 0.8;
}

#help {
  position: absolute;
  top: 109px;
  right: 40px;
  text-align: right;
}

#help,
#count,
#clear,
#hud {
}

.option-descriptor {
  letter-spacing: 1px;
  text-transform: uppercase;
}

#options {
  display: inline;
}

#option-set-phase {
  position: absolute;
  right: 220px;
  top: 15px;
}

#option-set-reason {
  position: absolute;
  right: 40px;
  top: 15px;
}

#sorts {
  position: absolute;
  right: 440px;
  top: 15px;
}

/* Modals */

#closeloadhelp {
  pointer-events: painted !important;
  color: black;
  text-decoration: underline;
  padding: 5px 10px 5px 35px;
  border-radius: 5px;
  background: white url(../img/start-icon.png) no-repeat 8px 7px;
  text-decoration: none;
  display: none;
}

#closeloadhelp:hover {
  color: black;
}

#loadhelp p {
  margin-top: 10px;
}

/* Social */

#share {
  position: absolute;
  bottom: 8px;
  right: 240px;
}

.share-button {
  background: url("../img/newsharesprite.png") no-repeat;
  width: 22px;
  height: 22px;
  display: inline-block;
  opacity: 0.4;
}

.share-button:hover {
  opacity: 1;
}

.share-button span {
  display: none;
}

.share-fb {
  background-position: 0px 0px;
}

.share-tw {
  background-position: -22px 0px;
}

.share-rd {
  background-position: -44px 0px;
}

.share-dg {
  background-position: -66px 0px;
}

/* Footer */

.key {
  display: inline-block;
  padding-left: 40px;
  height: 20px;
}

.key span {
  position: relative;
  top: 1px;
}

.key:first-child {
}

.key-intensity {
  background: url(../img/key-intensity.png) no-repeat top left;
}

.key-bubblesize {
  background: url(../img/key-bubblesize.png) no-repeat top left;
  margin-right: 20px;
}

.iibstudio {
  background: url(../img/iibstudio-for-bbcfuture.png) no-repeat top right;
  float: right;
  display: inline-block;
  height: 24px;
  width: 155px;
  position: fixed;
  bottom: 15px;
  right: 40px;
}

#source {
  z-index: 9999;
  color: #666;
  position: absolute;
  bottom: 240px;
  width: 180px;
  right: 10px;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
  -moz-transform: rotate(-90deg);
  -moz-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -o-transform-origin: bottom right;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
  transform-origin: bottom right;
}

/* Animation */

.anim-slow {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

.anim-slow-opacity {
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
}

.anim-xslow {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.anim-xslow-opacity {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.anim-fast {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.anim-fast-opacity {
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.anim-med {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Graph */

#summary {
  position: fixed;
  right: 40px;
  top: 150px;
  width: 250px;
  padding: 20px;
  background: #3c4044;
  border-radius: 5px;
  opacity: 0;
}

#summary .option-descriptor.stats {
  position: absolute;
  top: 20px;
  float: left;
  display: inline;
}

#summary-graph {
  width: 250px;
  position: absolute;
  top: 20px;
}

.baseline {
  position: absolute;
  bottom: 7px;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
}

.year {
  display: block;
  position: relative;
  left: 35px;
}

.year span.dot {
  position: absolute;
  bottom: -9px;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 6px;
  display: inline-block;
  margin-left: -2px;
}

.year-breakdown {
  display: none;
}

.year label.marker {
  text-align: right;
  position: absolute;
  width: 20px;
  left: -35px;
  top: -5px;
  display: inline-block;
  cursor: pointer;
}

.year label.marker:hover {
  color: white;
  opacity: 1 !important;
}

.year label.marker:hover + .bars .bar {
  z-index: 99999;
  height: 8px;
  margin-top: -2px;
}

.year label.marker:hover + .bars .bar_ghost {
}

.bar {
  position: absolute;
  left: 0px;
  width: 0px;
  height: 4px;
  margin-left: -5px;
}

.bar_ghost {
  background: transparent;
}

#year-breakdown-holder {
  display: none;
}

#summary-totals {
  position: absolute;
  line-height: 1.1rem;
  padding: 0px;
}

.summary-total {
  font-weight: 700;
  text-align: center;
  width: 60px;
  margin: auto;
  padding: 3px 0px 0px 0px;
  display: inline-block;
}

.summary-total-container.empty {
  opacity: 0.2;
  color: #aaa !important;
}

.summary-total-container {
  height: 27px;
  display: inline;
  width: 60px;
  float: left;
  margin: 5px 0px 0px -15px;
}

.summary-total-container .icon {
  width: 24px;
  height: 24px;
  background-image: url("../img/iconspritenew.png");
  display: block;
  padding: 2px;
  background-repeat: no-repeat;
  margin: auto;
}

#summary-total-human_error,
.panel-icon-human-error {
  color: rgba(255, 204, 5, 1);
}

#summary-total-weather,
.panel-icon-weather {
  color: rgba(53, 193, 224, 1);
}

#summary-total-mechanical,
.panel-icon-mechanical {
  color: rgba(250, 170, 67, 1);
}

#summary-total-criminal,
.panel-icon-criminal {
  color: rgba(213, 93, 38, 1);
}

#summary-total-unknown,
.panel-icon-unknown {
  color: rgba(153, 142, 124, 1);
}

#yeartip .summary-year-breakdown {
  margin-top: 10px;
}

#yeartip .summary-total-container {
  display: block;
  float: none;
  clear: both;
  width: 100%;
  margin: 0px 0px -12px -15px;
  position: relative;
  padding: 0px;
}

#yeartip .summary-total-container .icon {
  padding: 0px;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  float: left;
  margin: 0px 5px 0px 15px;
  top: 3px;
  position: relative;
  background-image: url("../img/iconspritexsmall.png");
}

#yeartip .stc-human_error .icon {
  background-position: 0px 0px;
}

#yeartip .stc-weather .icon {
  background-position: 0px -10px;
}

#yeartip .stc-mechanical .icon {
  background-position: 0px -20px;
}

#yeartip .stc-criminal .icon {
  background-position: 0px -30px;
}

#yeartip .stc-unknown .icon {
  background-position: 0px -40px;
}

#yeartip .stc-tally {
  display: inline-block;
  float: right;
  text-align: right;
  position: relative;
  left: 10px;
}

#summary-info {
  line-height: 1;
  position: absolute;
  bottom: 20px;
}

#summary-info p {
  margin: 0px;
}

#summary-info p:first-child {
}

/* First and last labels */

.start-date {
  position: absolute;
  top: 150px;
  left: 40px;
  color: white;
}

.end-date {
  position: absolute;
  color: white;
  left: 40px;
  margin-bottom: 100px;
  text-align: right;
}

.date-label-shown {
  border-bottom: 1px dashed #3c4044;
}

.date-label-shown span {
  display: inline-block;
  position: relative;
  top: 10px;
  background: #3c4044;
  border-radius: 3px;
  padding: 3px 6px;
}

.date-label {
  display: none;
  border-radius: 4px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

/* Bubbles */

.box {
  height: 0px;
  position: relative;
  background: none;
  margin-bottom: 75px;
}

.box-parent {
  opacity: 0;
}

.box-inner {
  background: #ccc;
  position: absolute;
  border: 3px solid transparent;
  color: #666;
  border-radius: 300px;
}

.box-inner.f {
  z-index: 9999999 !important;
  opacity: 1 !important;
  border: 3px solid transparent;
}

.box.f {
  opacity: 1 !important;
}

.box.h {
  opacity: 0.1;
}

.box span {
  display: none;
}

.box.c_unknown .box-inner {
  opacity: 0.2;
}

.box.c_suspected .box-inner {
  opacity: 0.4;
}

.box.c_probable .box-inner {
  opacity: 0.6;
}

.box.c_confirmed .box-inner {
  opacity: 0.8;
}

.box.r_human_error .box-inner,
.bar_human_error {
  background-color: rgba(255, 204, 5, 1);
}

.box.r_weather .box-inner,
.bar_weather {
  background-color: rgba(53, 193, 224, 1);
}

.box.r_mechanical .box-inner,
.bar_mechanical {
  background-color: rgba(250, 170, 67, 1);
}

.box.r_criminal .box-inner,
.bar_criminal {
  background-color: rgba(213, 93, 38, 1);
}

.box.r_unknown .box-inner,
.bar_unknown {
  background-color: rgba(153, 142, 124, 1);
}

/* Bubble detail popup */

.info {
  display: none;
}

.info-label {
  text-transform: uppercase;
  display: block;
  color: rgba(126, 134, 140, 1);
}

.info-l {
  font-family: "Oswald", sans-serif;
  margin-top: -5px;
  display: block;
}

.info-s {
  font-weight: bold;
}

.info-date {
}

.info-d {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 2px;
  width: 180px;
  position: relative;
  left: -10px;
  margin: 5px 0px;
}

/* Filters & sort */

.sort {
  display: inline;
}

span.label {
  display: inline;
  float: left;
}

#options input[type="checkbox"],
#summary input[type="checkbox"] {
  display: none;
}

#options input[type="checkbox"] + label {
  background-color: #303336;
  margin-left: -2px;
  display: inline-block;
  background-repeat: no-repeat;
  opacity: 0.6;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 24px;
  height: 24px;
  background-image: url("../img/iconspritenew.png");
  padding: 2px;
  border-radius: 7px;
  border-width: 1px;
  border-style: solid;
  position: relative;
  top: -2px;
  cursor: pointer;
}

#options input[type="checkbox"] + label:hover {
  opacity: 0.8 !important;
}

#options input[type="checkbox"]:checked + label {
  opacity: 1 !important;
}

#options label span {
  display: none;
}

#option-set-phase input[type="checkbox"] + label {
  border-color: rgba(255, 255, 255, 0);
}

#option-set-phase input[type="checkbox"] + label:hover {
  border-color: rgba(255, 255, 255, 0.6);
}

#option-set-phase input[type="checkbox"]:checked + label {
  border-color: rgba(255, 255, 255, 1);
}

#summary input[type="checkbox"]:checked + label {
  color: white !important;
  opacity: 1;
}

#summary input[type="checkbox"] + label:not(.selected) {
  opacity: 0.7;
}

#p_grounded + label {
  background-position: 0px 0px;
}

#p_takeoff + label {
  background-position: -28px 0px;
}

#p_initial_climb + label {
  background-position: -56px 0px;
}

#p_en_route + label {
  background-position: -84px 0px;
}

#p_approach + label {
  background-position: -112px 0px;
}

#p_landing + label {
  background-position: -140px 0px;
}

#r_human_error + label,
#summary-total-human_error .icon,
.stc-human_error .icon {
  background-position: -168px 0px;
  border-color: rgba(255, 204, 5, 0);
}

#r_human_error + label:hover {
  border-color: rgba(255, 204, 5, 0.6);
}

#r_human_error:checked + label {
  border-color: rgba(255, 204, 5, 1);
}

#r_weather + label,
#summary-total-weather .icon,
.stc-weather .icon {
  background-position: -196px 0px;
  border-color: rgba(53, 193, 224, 0);
}

#r_weather + label:hover {
  border-color: rgba(53, 193, 224, 0.6);
}

#r_weather:checked + label {
  border-color: rgba(53, 193, 224, 1);
}

#r_mechanical + label,
#summary-total-mechanical .icon,
.stc-mechanical .icon {
  background-position: -224px 0px;
  border-color: rgba(250, 170, 67, 0);
}

#r_mechanical + label:hover {
  border-color: rgba(250, 170, 67, 0.6);
}

#r_mechanical:checked + label {
  border-color: rgba(250, 170, 67, 1);
}

#r_criminal + label,
#summary-total-criminal .icon,
.stc-criminal .icon {
  background-position: -252px 0px;
  border-color: rgba(213, 93, 38, 0);
}

#r_criminal + label:hover {
  border-color: rgba(213, 93, 38, 0.6);
}

#r_criminal:checked + label {
  border-color: rgba(213, 93, 38, 1);
}

#r_unknown + label,
#summary-total-unknown .icon,
.stc-unknown .icon {
  background-position: -280px 0px;
  border-color: rgba(153, 142, 124, 0);
}

#r_unknown + label:hover {
  border-color: rgba(153, 142, 124, 0.6);
}

#r_unknown:checked + label {
  border-color: rgba(153, 142, 124, 1);
}

/* Loading */

#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  height: 0px;
  width: 0px;
  opacity: 1;
  z-index: 99999;
  color: #f2f2f2;
}

#spinner {
  position: relative;
  top: 15px;
  left: -55px;
}

#loading span {
  position: relative;
  top: 5px;
  left: -25px;
  width: 150px;
  text-align: left;
  display: inline-block;
}

#loadhelp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 210px;
  height: 205px;
  z-index: 99999;
  color: #f2f2f2;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  background: #3c4044;
  border-radius: 6px;
  text-align: center;
}

#loadhelp.out {
  top: 2000px;
}

/* Debug*/

#debug {
  position: fixed;
  bottom: 100px;
  left: -300px;
  background: black;
  padding: 5px;
  color: #46fa04;
  font-size: 10px;
  z-index: 9999;
  width: 300px;
  border-right: 20px solid black;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.9;
  display: none;
}

#debug:hover {
  left: 0px;
  opacity: 0.9;
}

.debug_float {
  width: 60px;
  float: left;
}

/* Powertip */

#labeltip,
.info-s,
.info-l,
.chosen-results,
#yeartip {
  color: rgba(80, 93, 104, 1);
}

#tip,
#labeltip,
#yeartip {
  cursor: default;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, 0.9);
  border-radius: 3px;
  display: none;
  position: absolute;
  z-index: 2147483647;
  word-wrap: normal;
  white-space: pre-line;
}

#tip {
  padding: 10px;
}

#tip,
#yeartip {
  text-align: left;
  width: 160px;
}

#labeltip {
  text-align: center;
  padding: 5px;
}

#yeartip {
  position: fixed;
  padding: 10px;
}

#tip:before,
#labeltip:before,
#yeartip:before {
  content: "";
  position: absolute;
}

#tip.n:before,
#yeartip.n:before,
#labeltip.n:before,
#tip.s:before,
#yeartip.s:before,
#labeltip.s:before {
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  left: 50%;
  margin-left: -5px;
}

#tip.e:before,
#yeartip.e:before,
#labeltip.e:before,
#tip.w:before,
#yeartip.w:before,
#labeltip.w:before {
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  margin-top: -5px;
  top: 50%;
}

#tip.n:before,
#yeartip.n:before,
#labeltip.n:before {
  border-top: 10px solid #f2f2f2;
  border-top: 10px solid rgba(242, 242, 242, 0.9);
  bottom: -10px;
}

#tip.e:before,
#yeartip.e:before,
#labeltip.e:before {
  border-right: 10px solid #f2f2f2;
  border-right: 10px solid rgba(242, 242, 242, 0.9);
  left: -10px;
}

#tip.s:before,
#yeartip.s:before,
#labeltip.s:before {
  border-bottom: 10px solid #f2f2f2;
  border-bottom: 10px solid rgba(242, 242, 242, 0.9);
  top: -10px;
}

#tip.w:before,
#yeartip.w:before,
#labeltip.w:before {
  border-left: 10px solid #f2f2f2;
  border-left: 10px solid rgba(242, 242, 242, 0.9);
  right: -10px;
}

#tip.ne:before,
#yeartip.ne:before,
#labeltip.ne:before,
#tip.se:before,
#yeartip.se:before,
#labeltip.se:before {
  border-right: 10px solid transparent;
  border-left: 0;
  left: 10px;
}

#tip.nw:before,
#yeartip.nw:before,
#labeltip.nw:before,
#tip.sw:before,
#yeartip.sw:before,
#labeltip.sw:before {
  border-left: 10px solid transparent;
  border-right: 0;
  right: 10px;
}

#tip.ne:before,
#yeartip.ne:before,
#labeltip.ne:before,
#tip.nw:before,
#yeartip.nw:before,
#labeltip.nw:before {
  border-top: 10px solid #f2f2f2;
  border-top: 10px solid rgba(242, 242, 242, 0.9);
  bottom: -10px;
}

#tip.se:before,
#yeartip.se:before,
#labeltip.se:before,
#tip.sw:before,
#yeartip.sw:before,
#labeltip.sw:before {
  border-bottom: 10px solid #f2f2f2;
  border-bottom: 10px solid rgba(242, 242, 242, 0.9);
  top: -10px;
}

#tip.nw-alt:before,
#yeartip.nw-alt:before,
#labeltip.nw-alt:before,
#tip.ne-alt:before,
#yeartip.ne-alt:before,
#labeltip.ne-alt:before,
#tip.sw-alt:before,
#yeartip.sw-alt:before,
#labeltip.sw-alt:before,
#tip.se-alt:before,
#yeartip.se-alt:before,
#labeltip.se-alt:before {
  border-top: 10px solid #f2f2f2;
  border-top: 10px solid rgba(242, 242, 242, 0.9);
  bottom: -10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 10px;
}

#tip.ne-alt:before,
#yeartip.ne-alt:before,
#labeltip.ne-alt:before {
  left: auto;
  right: 10px;
}

#tip.sw-alt:before,
#yeartip.sw-alt:before,
#labeltip.sw-alt:before,
#tip.se-alt:before,
#yeartip.se-alt:before,
#labeltip.se-alt:before {
  border-top: 0;
  border-bottom: 10px solid #f2f2f2;
  border-bottom: 10px solid rgba(242, 242, 242, 0.9);
  bottom: auto;
  top: -10px;
}

#tip.se-alt:before,
#yeartip.se-alt:before,
#labeltip.se-alt:before {
  left: auto;
  right: 10px;
}

/* Chosen */

.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: 1px;
}

.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border-top: 0;
  background: #f2f2f2;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
}

.chosen-container a {
  cursor: pointer;
}

.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 8px;
  height: 23px;
  border-radius: 5px;
  background-color: #f2f2f2;
  background-clip: padding-box;
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  line-height: 24px;
}

.chosen-container-single .chosen-default {
  color: #999;
}

.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}

.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("../img/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}

.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}

.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}

.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%;
}

.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: url("../img/chosen-sprite.png") no-repeat 0 2px;
}

.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}

.chosen-container-single .chosen-search input[type="text"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  height: auto;
  outline: 0;
  background: white url("../img/chosen-sprite.png") no-repeat 100% -20px;
  background: url("../img/chosen-sprite.png") no-repeat 100% -20px;
  font-size: 1em;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 0;
}

.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px;
}

.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  text-indent: 2px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li {
  width: 100%;
  display: none;
  margin: 0;
  padding: 5px 6px;
  list-style: none;
  line-height: 15px;
  -webkit-touch-callout: none;
}

.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
  width: 108px;
}

li.active-result:last-child:hover {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}

.chosen-container .chosen-results li.highlighted {
  background-color: rgba(255, 204, 5, 1);
  color: black;
}

.chosen-container .chosen-results li.no-results {
  display: list-item;
  background: #f4f4f4;
}

.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}

.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}

.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}

.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto !important;
  height: 1%;
  border: 1px solid #aaa;
  cursor: text;
}

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}

.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 1px 0;
  padding: 5px;
  height: 15px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none;
  color: #666;
  font-size: 100%;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 0;
}

.chosen-container-multi .chosen-choices li.search-field .default {
  color: #999;
}

.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  border-radius: 3px;
  background-clip: padding-box;
  color: #333;
  line-height: 13px;
  cursor: default;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("../img/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}

.chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close:hover {
  background-position: -42px -10px;
}

.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  background-color: #e4e4e4;
  color: #666;
}

.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}

.chosen-container-multi
  .chosen-choices
  li.search-choice-focus
  .search-choice-close {
  background-position: -42px -10px;
}

.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}

.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: 0;
  background: transparent;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 2px;
}

.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #111 !important;
}

.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}

.chosen-disabled .chosen-single {
  cursor: default;
}

.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

.chosen-rtl {
  text-align: right;
}

.chosen-rtl .chosen-single {
  overflow: visible;
  padding: 0 8px 0 0;
}

.chosen-rtl .chosen-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}

.chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}

.chosen-rtl .chosen-single div {
  right: auto;
  left: 3px;
}

.chosen-rtl .chosen-single abbr {
  right: auto;
  left: 26px;
}

.chosen-rtl .chosen-choices li {
  float: right;
}

.chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}

.chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}

.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  right: auto;
  left: 4px;
}

.chosen-rtl.chosen-container-single-nosearch .chosen-search,
.chosen-rtl .chosen-drop {
  left: 9999px;
}

.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}

.chosen-rtl .chosen-results li.group-option {
  padding-right: 0;
  padding-left: 0;
}

.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: 0;
}

.chosen-rtl .chosen-search input[type="text"] {
  padding: 4px 5px 4px 20px;
  background: white url("../img/chosen-sprite.png") no-repeat -30px -20px;
  background: url("../img/chosen-sprite.png") no-repeat -30px -20px;
  direction: rtl;
}

.chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}

.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("../img/chosen-sprite@2x.png") !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
}

/* Media queries */

@media (max-width: 1020px) {
}

@media (max-width: 820px) {
  #hud,
  #help,
  .key {
    display: none;
  }
}

/* Browser upgrade */
#browser {
  font-size: 1rem;
  display: none;
  width: 500px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 50px;
  vertical-align: middle;
  text-align: center;
}

html.no-cssanimations #browser,
html.no-json #browser {
  display: block;
}

html.no-cssanimations .hfo,
html.no-json .hfo {
  display: none !important;
}
